<template>
  <div class="stack">
    <div class="stack-header">
      <tabs :list="nav" button="storage" class="medium-title hack">
        <template #item="{ item }">
          <router-link active-class="active" :to="item.to">
            {{ item.title }}
          </router-link>
        </template>
        <template #button>
          <div v-if="s3keys.length" class="buttons">
            <div class="mr">
              <base-button
                :class="{ visible: s3keys.length }"
                class="stack-info__create-btn"
                @click="newStorage(tariff.id)"
              >
                {{ $t('new') }}
              </base-button>
            </div>
            <div>
              <base-button
                theme="outlined"
                class="stack-info__create-btn"
                color="primary"
                :to="{ name: 'containerAccess', hash: 's3Keys' }"
              >
                {{ $t('s3keys') }}
              </base-button>
            </div>
          </div>
        </template>
      </tabs>
    </div>
    <transition name="bubble">
      <router-view :tariff="tariff"></router-view>
    </transition>
  </div>
</template>

<script>
import tabs from '@/components/Tabs/Tabs';
import newStorage from '@/mixins/newStorage';

export default {
  name: 'ContainerS3Nav',
  components: {
    tabs,
  },
  props: {
    tariff: {
      type: Object,
      required: true,
    },
  },
  mixins: [newStorage],
  data() {
    return {
      navRaw: [
        {
          title: this.$t('nav.storage'),
          to: { name: 'viewStorage' },
        },
        {
          title: this.$t('nav.view'),
          to: { name: 'S3View' },
        },
      ],
    };
  },
  computed: {
    s3keys() {
      return this.$store.state.moduleStack.s3Keys;
    },
    nav() {
      return this.navRaw.filter(
        i => !i.access || (i.access && this[i.access.name] === i.access.value)
      );
    },
  },
  beforeRouteLeave(_to, _from, next) {
    // this.reset();
    next();
  },
  methods: {
    // validateOpenStackApiKey() {
    //   return this.$store.dispatch('moduleStack/validateOpenStackApiKey', this.tariff.id);
    // },
    // reset() {
    // this.$store.dispatch('moduleStack/resetBalancer');
    // this.$store.dispatch('moduleStack/resetStorage');
    // },
  },
};
</script>

<i18n>
{
  "ru": {
    "newRule": "Добавить балансировщик",
    "title": {
      "server": "Сети",
      "plan": "Тарифный план",
      "stats": "Статистика"
    },
    "s3keys": "Ключи S3",
    "new": "Добавить контейнер",
    "prolong": {
      "confirm" : "Сменить",
      "order": "Отмена"
    },
    "nav": {
      "balancer": "Балансировщики",
      "storage": "Контейнеры",
      "view": "Обзор",
      "networkext": "Публичные подсети",
      "router": "Роутеры",
      "firewall": "Файрвол"
    },
    "newServer": "Новая сеть",
    "activeUnder": "Действует до {date}",
    "actions": {
      "prolong": "Продлить",
      "changePlan": "Сменить тариф"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.hack{
  width: -moz-available;
  width:-webkit-fill-available;
}
.mr {
  //margin-right: 1.5rem

}
.buttons {
  display: flex;
  gap: 1rem;
}
  .storage {
    margin-top: -1.5rem
  }
.stack {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;
  &-header {
    justify-content: flex-start;
    margin-right: 1.5rem;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    flex: 1 1 auto;
    +breakpoint(sm-and-up) {
      //flexy(space-betweeb);
      flexy(space-between, center);
      //flex-direction: column;
    }

    //&__content {
    //  flexy(space-between, center);

      +breakpoint(sm-and-up) {
        justify-content: flex-start;
        margin-right: 1.5rem;
        margin-top: 0.75rem;
        margin-bottom: 0.75rem;
        flex: 1 1 auto;
      //}
    }
  }
  &-info {
    +breakpoint(sm-and-up) {
      flexy(space-betweeb, center);
    }

    &__content {
      flexy(space-between, center);

      +breakpoint(sm-and-up) {
        justify-content: flex-start;
        margin-right: 1.5rem;
        flex: 1 1 auto;
      }
    }

    &__loader {
      flexy(center, center);
      flex: 1 1 auto;
    }

    &__create {
      flex: 0 0 auto;
      margin-top: 1.5rem;
      max-width: 160px;

      +breakpoint(sm-and-up) {
        margin: 0 0 0 auto;
      }
    }
  }

  &-mode {
    flexy(flex-end, center);
    margin-left: auto;

    +breakpoint(sm-and-up) {
      justify-content: flex-start;
      margin-left: 1.5rem;
    }

    &__btn {
      & + & {
        margin-left: 1rem;
      }
    }
  }

  &-content {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2rem;
    }
    +breakpoint(md-and-up) {
      margin-top: 2.5rem;
    }
  }
}
</style>

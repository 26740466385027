<template>
  <div>
    <div class="stack-order page">
      <transition name="fade" mode="out-in">
        <div class="stack-order__content">
          <!--          <transition name="fade" mode="out-in">-->
          <!--            <div v-if="!isValid" class="l-col">-->
          <!--              <p v-html="validation"></p>-->
          <!--            </div>-->
          <!--          </transition>-->
          <div class="l-col">
            <!--              :hint="$t('balancer.hint')"-->
            <base-input
              v-model="name"
              :custom-error-messages="patternValidation"
              type="text"
              :name="$t('balancer.name')"
              :required="true"
              :label="$t('balancer.name')"
              :hint="validation"
              use-reactive-validation
              :pattern="error ? 'true' : name"
              class="form__field--input"
              @input="onChange($event, 'name')"
            >
              <!--              <p v-if="error" slot="storageError">{{ $t(`${error}`) }}</p>-->
              <!--              <slot v-if="error" name="storageError">{{ error }}</slot>-->
            </base-input>
          </div>
          <div class="l-col resize-type__config paragraf-up">
            <label class="typo__label standart-title">{{ $t('type') }}</label>
            <div v-for="opt in navFlavor" :key="opt.k" class="resize-type__config">
              <base-radio
                v-model="type"
                :value="opt.k"
                theme="tile"
                :label="$t('type')"
                class="resize-type__config-item standart-title"
                @change="onChange($event, 'type')"
              >
                <label class="typo__label standart-title">{{ opt.v }}</label>
                <br />
              </base-radio>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import BaseInput from '@/components/BaseInput/BaseInput';
import BaseRadio from '@/components/Checkboxes/BaseRadio';
import BaseSelect from '@/components/Select/BaseSelect';
import networks from '@/layouts/Stack/mixins/networks';
import storeMixin from '@/layouts/Stack/mixins';
import AddNewRule from '@/layouts/Stack/pages/Main/components/AddNewRule';
import BaseButton from '@/components/Buttons/BaseButton';
import showErrorModal from '@/mixins/showErrorModal';
import attachFloatingIp from '@/mixins/attachFloatingIp';
import { IS_PROD } from '@/layouts/LoginPage/mixins/currentProvider';

export default {
  name: 'NewStorage',
  components: {
    // BaseLoader,
    BaseRadio,
    // BaseSelect,
    BaseInput,
  },
  mixins: [],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      // customValidationMsgs: {
      //   confirm: { patternMismatch: 'test' },
      // },
      name: '',
      isValid: false,
      type: 'private',
      error: '',
    };
  },
  computed: {
    // dev() {
    //   return IS_PROD ? 'stat' : 'full_price';
    // },
    // project() {
    //   return this.$store.getters['moduleStack/project_id'];
    // },
    validation() {
      return `Требования к названию контейнера:<br/>
      - название контейнера должно быть уникально;<br/>
- длина от 3 до 63 символов;<br/>
- допускаются только строчные латинские буквы, цифры, точки и дефисы;<br/>
- не может начинаться или заканчиваться точкой или дефисом;<br/>
- не может содержать две точки подряд, точку и дефис подряд ('-.' или '.-');<br/>
- не может быть IP-адресом.<br/>
      `;
    },
    usedStorages() {
      return this.$store.state.moduleStack.storages.map(x => x.name);
    },
    patternValidation() {
      return {
        // customValidationMsgs: {
        confirm: {
          patternMismatch: `${this.error ? this.$t(this.error) : null}`,
        },
        // },
      };
    },
    publicPolicy() {
      return {
        Version: '2012-10-17',
        Statement: [
          {
            Sid: 'public',
            Action: 's3:GetObject',
            Effect: 'Allow',
            Principal: '*',
            Resource: `arn:aws:s3:::${this.name}/*`,
          },
        ],
      };
    },
    navFlavor() {
      return [
        {
          v: this.$t('nav.private'),
          text: this.$t('nav.private'),
          k: 'private',
        },
        {
          v: this.$t('nav.public'),
          k: 'public',
          text: this.$t('nav.public'),
        },
      ];
    },
  },
  watch: {
    name: {
      handler: function (event) {
        if (this.usedStorages.includes(event)) this.error = 'used';
        else if (/^[a-z0-9](?!.*[.-]{2})[a-z0-9\-.]{1,61}[a-z0-9]$/.test(event)) {
          this.isValid = true;
          this.error = '';
        } else {
          this.isValid = false;
          if (event.length < 3 || event.length > 63) this.error = 'length';
          // if (event.toUpperCase() === event) this.error = 'upper';
          // else if (event.includes('/')) this.error = 'slash';
          if (event.includes('/')) this.error = 'slash';
          // else if (event.includes('_')) this.error = 'down';
          if (event.includes('_')) this.error = 'down';
          // else if (event.includes('_.' || '._' || '..' || '--')) this.error = 'down';
          if (event.includes('_.' || '._' || '..' || '--')) this.error = 'down';
          // else if (/[A-Z]/.test(event)) this.error = 'upper';
          if (/[A-Z]/.test(event)) this.error = 'upper';
          // else if (event.length < 3 || event.length > 63) this.error = 'length';
          // else if (event.toUpperCase() === event) this.error = 'upper';
          if (event.toUpperCase() === event && !/\d/.test(event)) this.error = 'upper';
          // else this.error = 'someError';
        }
      },
    },
    error: {
      handler: function (event) {
        if (event) this.$emit('notready');
      },
    },
    isValid: {
      handler: function (event) {
        // console.log(event);
        if (!event) this.$emit('notready');
      },
    },
  },
  async mounted() {
    this.$emit('notready');
  },
  methods: {
    onChange(event, type) {
      // console.log(event, type);
      if (type === 'name') {
        const payload = {
          name: event,
        };
        if (this.type === 'public') payload.policy = this.publicPolicy;

        if (!this.error) this.$emit('change', payload);
      }
      if (type === 'type') {
        const payload = {
          // policy: this.publicPolicy,
          name: this.name,
        };
        if (this.type === 'public') payload.policy = this.publicPolicy;
        if (!this.error) this.$emit('change', payload);
      }
    },
  },
};
</script>
<i18n>
{
  "ru": {
    "title": "Новый контейнер:",
    "used": "Имя контейнера уже используется.",
    "slash": "Недопустимый символ -'/'.",
    "down": "Недопустимый символ -'_'.",
    "someError": "Имя контейнера содержит неподдерживаемые символы. Пожалуйста, выберите другое имя.",
    "upper": "Нельзя использовать заглавные буквы.",
    "length": "Недопустима длина имени контейнера.",
    "error": "Недопустимый IP-адрес",
    "type": "Тип:",
    "day": "День",
    "hour": "Час",
    "month": "Месяц",
    "subnet": "Подсеть",
    "attachTo": "Подключить",
    "newRule": "Добавить правило",
    "newBalancer": "Создать балансировщик",
    "addRule": "Добавить еще правило",
    "port": "IP балансировщика",
    "portAviable": "IP балансировщика",
    "public": "Подключить плавающий IP",
    "warnings": "Обращаем ваше внимание на то, что расходы формируются раз в сутки, а счёт выставляется за фактически отработанное время. Сами расчёты осуществляются каждые 5 минут. \n Представленные ниже цены рассчитаны исходя из того, что сервер проработает полный период: час, день, месяц (30 дней).",

    "nav": {
      "private": "Приватный",
      "public": "Публичный"
    },
    "additional": "Стоимость",
    "balancer" : {
      "name" : "Имя контейнера:",
      "title" : "Правило",
      "hint" : "Имя контейнера должно быть уникальным в рамках проекта. Длинной от 3 до 63 символов без '/'"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.paragraf {
  margin-bottom: 10px;
  &-up{
    margin-top: 10px;

  }
}
.opacity {
  opacity: 1
}
.resize-type {

  &__config {
    display: flex;
    margin-top: 1rem;
    justify-content: space-between;
    text-align: left;
    align-items: baseline;


    &-item {
      min-width: 10rem;
      font-size: 20px;
      line-height: 26px;

      +breakpoint(sm-and-up) {
        min-width: 10rem;

      }
    }
  }
}
.stack-order {
  &__loading {
    flexy(center, center);
    flex: 1 1 100%;
  }
  &__sum {
    flex: 0 0 100%;

    +breakpoint(ms-and-up) {
      flex: 0 0 auto;
      margin-right: 0.5rem;
    }
  }
  &__price {
    flexy(flex-start, center, wrap);

    &-text {
      margin-right: 0.5rem;

      +breakpoint(sm-and-up) {
        font-size: $font-size-bigger;
      }
    }
  }
  &__period {
    flex: 0 0 8.3rem;

    +breakpoint(sm-and-up) {
      flex: 0 0 10rem;
    }
  }

  &__actions {
    margin: 0.75rem -0.75rem -0.75rem;
    flexy(flex-start, center, wrap);
  }

  &__btn {
    margin: 0.75rem;

    +breakpoint(xs-only) {
      flex: 1 1 100%;
    }
  }
}
.order-summary {
  &__list {
    no-style-list();
  }
  &__item {
    +breakpoint(sm-and-up) {
      flexy(flex-start, baseline);
    }
    & + & {
      //margin-top: 1.25rem;
      +breakpoint(sm-and-up) {
        margin-top: 0.25rem;
      }
    }
  }
  &__label {
    +breakpoint(sm-and-up) {
      flex: 0 0 45%;
      margin-right: 1.5rem;
    &-center {
      text-align: center
    }
    }
  }
  &__value {
    +breakpoint(sm-and-up) {
      flex: 0 0 25%;
      margin-right: 1.5rem;
      &-center {
      text-align: center
    }
    }
  }
  &__price {
    +breakpoint(sm-and-up) {
      flex: 0 0 25%;
      margin-right: 1.5rem;
      &-center {
      text-align: center
    }
    }
  }
}
</style>
